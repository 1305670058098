import React from "react";
import { Divider } from "primereact/divider";
import "./sidebarOverlay.css"

const MISidebar = ({ data, mapURL }) => {
  return (
    <div className="sideheader">
      {data ? (
        <>
          <div className="view-details-div">
            <label style={{color: "#ED7D31",fontFamily: "Calibri",fontWeight: "600",fontSize: "13px",}} >
            Property Details
            </label>
            <div className="viewdetailsmap">
              <iframe
                className="viewdetailsmapstyle" loading="lazy" allowFullScreen referrerPolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyB5Bb90wAs9U0cLmj-8hSlQVf_lQd_jJH8&q=${mapURL}`}></iframe>
            </div>
            <div className="view-details-style">
              <p>Property Name:{" "}<span>{data.nedl_property_name !== null ? data.nedl_property_name : "Not Available"}</span></p>
              <p>Address:{" "}
                <span style={{ float: "right", fontWeight: "600" }}>
{data.property_address !== null ? data.property_address : "Not Available"}
                </span>
              </p>
              <p>
                City:{" "}
                <span style={{ float: "right", fontWeight: "600" }}>
                  {data.city !== null
                    ? data.city
                    : "Not Available"}
                </span>
              </p>
              <p>
                State:{" "}
                <span style={{ float: "right", fontWeight: "600" }}>
                  {data.state !== null
                    ? data.state
                    : "Not Available"}
                </span>
              </p>
              <p>
                Zip:{" "}
                <span style={{ float: "right", fontWeight: "600" }}>
                  {data.zip !== null
                    ? data.zip
                    : "Not Available"}
                </span>
              </p>
              <p>
                Property Units:{" "}
                <span style={{ float: "right", fontWeight: "600" }}>
                  {data.units_count !== null
                    ? data.units_count
                    : "Not Available"}
                </span>
              </p>
              <p>
                Property Amount:{" "}
                <span style={{ float: "right", fontWeight: "600" }}>
                  {data.document_amount !== null
                    ? `$${(data.document_amount / 1000000).toFixed(2)}M`
                    : "Not Available"}
                </span>
              </p>
              {/* <p >Property Asset Class: <span style={{ float: "right", fontWeight: "600" }}>{data.property_asset_class !== null ? data.property_asset_class : "Not Available"}</span></p> */}
              {/* <p >Market: <span style={{ float: "right", fontWeight: "600" }}>{data.market !== null ? data.market : "Not Available"}</span></p> */}
              <p>
                Buyer:{" "}
                <span style={{ float: "right", fontWeight: "600" }}>
                  {data.grantee_name !== null
                    ? data.grantee_name
                    : "Not Available"}
                </span>
              </p>
              <p>
                Seller:{" "}
                <span style={{ float: "right", fontWeight: "600" }}>
                  {data.grantor_name !== null
                    ? data.grantor_name
                    : "Not Available"}
                </span>
              </p>
              <p>
                Loan Amount:{" "}
                <span style={{ float: "right", fontWeight: "600" }}>
                  {data.loan_amount !== null && data.loan_amount !== 0
                    ? `$${(data.loan_amount / 1000000).toFixed(2)}M`
                    : "Not Available"}
                </span>
              </p>

              <Divider />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default MISidebar;
