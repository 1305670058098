import React from 'react'
import GenericPdfDownloader from "../../components/AAANEWCOMPONENT/download";
import DatahubAreaChart from "../../components/HighCharts/DatahubAreaChart/DatahubAreaChart";


const viewDetailsDatahub = ({leadsInfo,mapURL,nearbymapURL,formatCurrency,userToken,numberWithCommas}) => {
  return (
    <div className="vdsidebarContainer">
            <div className="vdheader">
            <div>Property Details
             <span className="vdpropcount">{` : ${leadsInfo.property_name !== null ? leadsInfo.property_name : 'Not Available'}`}</span>
             </div>
            <div>
              <GenericPdfDownloader 
                downloadFileName="DataHubPdf" 
            rootElementId="testId" 
            stylebtn="vddownloadbtn"
            /> 
            </div>
            </div>
            <div className='vdContentContainer'>
            <div className="vdmapContainer">
              <div className="vdmapdiv">
              <div className="vdmapdetails">
                  <div className="vdmapdetailstyle"><label className="vdmapdetailstyletitle">Address</label><label style={{maxWidth:"100%"}} className="vdmapdetailstylecontent">{leadsInfo.prop_address !== null ? leadsInfo.prop_address : 'Not Available'}</label></div>
                  <div className="vdmapdetailstyle"><label className="vdmapdetailstyletitle">City</label><label className="vdmapdetailstylecontent">{leadsInfo.prop_city !== null ? leadsInfo.prop_city : 'Not Available'}</label></div>
                  <div className="vdmapdetailstyle"><label className="vdmapdetailstyletitle">State</label><label className="vdmapdetailstylecontent" >{leadsInfo.prop_state !== null ? leadsInfo.prop_state : 'Not Available'}</label></div>
                  <div className="vdmapdetailstyle"><label className="vdmapdetailstyletitle">County</label><label className="vdmapdetailstylecontent">{leadsInfo.prop_county !== null ? leadsInfo.prop_county : 'Not Available'}</label></div>
                  <div className="vdmapdetailstyle"><label className="vdmapdetailstyletitle">Zip</label><label className="vdmapdetailstylecontent">{leadsInfo.prop_zip !== null ? leadsInfo.prop_zip : 'Not Available'}</label></div>
                  <div className="vdmapdetailstyle"><label className="vdmapdetailstyletitle">Units</label><label className="vdmapdetailstylecontent">{leadsInfo.units_count !== null ? leadsInfo.units_count : 'Not Available'}</label></div>
                </div>
                <div className="vdmap">
                <iframe className="viewdetailsmapstyle"
  loading="lazy"
  allowFullScreen
  referrerPolicy="no-referrer-when-downgrade"
  src={`https://www.google.com/maps/embed/v1/view?key=AIzaSyB5Bb90wAs9U0cLmj-8hSlQVf_lQd_jJH8
  &zoom=20${mapURL}
  &maptype=satellite`}>
</iframe>
                </div>
                <fieldset className='fieldsetdh'>
                <legend className="vdmapdetailstyleowner"><label style={{color:"#ED7D31"}} className="vdmapdetailstyletitleowner">Owner Details</label>
                </legend>
                <div className='vdpropmaptitleowner'>
                <div className="vdmapdetails vdmapdetailsowner">
                  <div>
                  <div className="vdmapdetailstyleowner"><label className="vdmapdetailstyletitleowner">Name :</label><label style={{maxWidth:"100%"}} className="vdmapdetailstylecontentowner">{leadsInfo.owner_name !== null ? leadsInfo.owner_name : 'Not Available'}</label></div>
                  <div className="vdmapdetailstyleowner"><label className="vdmapdetailstyletitleowner">Contact Name :</label><label style={{maxWidth:"100%"}} className="vdmapdetailstylecontentowner">{leadsInfo.owner_contact_name !== null ? leadsInfo.owner_contact_name : 'Not Available'}</label></div>
                  </div>
                  <div>
                  <div className="vdmapdetailstyleowner"><label className="vdmapdetailstyletitleowner">Contact :</label><label className="vdmapdetailstylecontentowner">{leadsInfo.owner_phone_number !== null ? leadsInfo.owner_phone_number : 'Not Available'}</label></div>
                  <div className="vdmapdetailstyleowner"><label className="vdmapdetailstyletitleowner">Address :</label><label className="vdmapdetailstylecontentowner">{leadsInfo.owner_address !== null ? leadsInfo.owner_address : 'Not Available'}</label></div>
                  </div>
                  <div>
                  <div className="vdmapdetailstyleowner"><label className="vdmapdetailstyletitleowner">Email :</label><label className="vdmapdetailstylecontentowner" >{leadsInfo.owner_email_address !== null ? leadsInfo.owner_email_address : 'Not Available'}</label></div>
                  <div className="vdmapdetails vdmapdetailsowner">
                  <div className="vdmapdetailstyleowner"><label className="vdmapdetailstyletitleowner">State :</label><label className="vdmapdetailstylecontentowner" >{leadsInfo.owner_state !== null ? leadsInfo.owner_state : 'Not Available'}</label></div>
                  <div className="vdmapdetailstyleowner"><label className="vdmapdetailstyletitleowner">City :</label><label className="vdmapdetailstylecontentowner" >{leadsInfo.owner_city !== null ? leadsInfo.owner_city : 'Not Available'}</label></div>
                  </div>
                  </div>
                  </div>

                {/* <div className="vdmapdetails vdmapdetailsowner">
                  <div className="vdmapdetailstyleowner"><label className="vdmapdetailstyletitleowner">Name :</label><label style={{maxWidth:"100%"}} className="vdmapdetailstylecontentowner">{leadsInfo.owner_name !== null ? leadsInfo.owner_name : 'Not Available'}</label></div>
                  <div className="vdmapdetailstyleowner"><label className="vdmapdetailstyletitleowner">Contact :</label><label className="vdmapdetailstylecontentowner">{leadsInfo.owner_phone_number !== null ? leadsInfo.owner_phone_number : 'Not Available'}</label></div>
                  <div className="vdmapdetailstyleowner"><label className="vdmapdetailstyletitleowner">Email :</label><label className="vdmapdetailstylecontentowner" >{leadsInfo.owner_email_address !== null ? leadsInfo.owner_email_address : 'Not Available'}</label></div>
                </div>
                <div className="vdmapdetails vdmapdetailsowner">
                  <div className="vdmapdetailstyleowner"><label className="vdmapdetailstyletitleowner">Contact Name :</label><label style={{maxWidth:"100%"}} className="vdmapdetailstylecontentowner">{leadsInfo.owner_contact_name !== null ? leadsInfo.owner_contact_name : 'Not Available'}</label></div>
                  <div className="vdmapdetailstyleowner"><label className="vdmapdetailstyletitleowner">Address :</label><label className="vdmapdetailstylecontentowner">{leadsInfo.owner_address !== null ? leadsInfo.owner_address : 'Not Available'}</label></div>
                  <div className="vdmapdetailstyleowner"><label className="vdmapdetailstyletitleowner">State :</label><label className="vdmapdetailstylecontentowner" >{leadsInfo.owner_state !== null ? leadsInfo.owner_state : 'Not Available'}</label></div>
                  <div className="vdmapdetailstyleowner"><label className="vdmapdetailstyletitleowner">City :</label><label className="vdmapdetailstylecontentowner" >{leadsInfo.owner_city !== null ? leadsInfo.owner_city : 'Not Available'}</label></div>
                </div> */}
                </div>
                </fieldset>
              </div>
              <div className="vdmapdiv">
              <div className="vdmaptitle">NEARBY PROPERTIES</div>
                <div className="vdmap">
                <iframe className="viewdetailsmapstyle"
  loading="lazy"
  allowFullScreen
  referrerPolicy="no-referrer-when-downgrade"
  src={`https://www.google.com/maps/embed/v1/search?key=AIzaSyB5Bb90wAs9U0cLmj-8hSlQVf_lQd_jJH8
  &q=properties+in+${nearbymapURL}&zoom=14`}>
</iframe>
                </div>
                <div className="vdmapdetails">
                  <div className="vdmapdetailstyle"><label className="vdmapdetailstyletitle">Properties in the Area</label><label className="vdmapdetailstylecontent">{leadsInfo.prop_in_area !== null ? numberWithCommas(leadsInfo.prop_in_area) : 'Not Available'}</label></div>
                  <div className="vdmapdetailstyle"><label className="vdmapdetailstyletitle">Total Units in the Area</label><label className="vdmapdetailstylecontent">{leadsInfo.tot_unit_in_area !== null ? numberWithCommas(leadsInfo.tot_unit_in_area) : 'Not Available'}</label></div>
                  <div className="vdmapdetailstyle"><label className="vdmapdetailstyletitle">Total SqFt in the Area</label><label className="vdmapdetailstylecontent">{leadsInfo.tot_sqft_in_area !== null ? numberWithCommas(leadsInfo.tot_sqft_in_area) : 'Not Available'}</label></div>
                </div>
              </div>
            </div>
            <div className="vddataContainer">
            <div className="vddatadiv">
              <div className="vddatatitle">RENT AND OCCUPANCY</div>
              <div className="vd4cardsContainer">
              <div className="vd4cards">
                  <div className="vd4cardsstyle">
                    <div className="vd4cardsdiv">
                    <div className="vd4cardstitle">Average Rental Rate</div>
                    <div className="vd4cardsvalue">{leadsInfo.current_rental_rate !== null ? formatCurrency(leadsInfo.current_rental_rate) : 'Not Available'}</div>
                    <div className="vd4cardsfooter">{leadsInfo.bed_count !== null ? `${leadsInfo.bed_count} bedroom` : 'Not Available'}</div>
                    </div>
                    <div>
                      <img src="\images\lattest\Average Rental Rate.svg" />
                    </div>
                  </div>
                  <div className="vd4cardsstyle">
                    <div className="vd4cardsdiv">
                    <div className="vd4cardstitle">Average Rent per SqFt</div>
                    <div className="vd4cardsvalue">{leadsInfo.current_avg_rental_per_sqft !== null ? formatCurrency(leadsInfo.current_avg_rental_per_sqft) : 'Not Available'}</div>
                    <div className="vd4cardsfooter">{leadsInfo.bed_count !== null ?  `${leadsInfo.bed_count} bedroom`  : 'Not Available'}</div>
                    </div>
                    <div>
                      <img src="\images\lattest\Average Rent per SqFt.svg" />
                    </div>
                  </div>
                  <div className="vd4cardsstyle">
                    <div className="vd4cardsdiv">
                    <div className="vd4cardstitle">Occupancy Rate</div>
                    <div className="vd4cardsvalue">{leadsInfo.prev_quarter_occ_rate !== null ? `${((leadsInfo.prev_quarter_occ_rate) *1).toFixed(2)}%` : 'Not Available'}</div>
                    <div className="vd4cardsfooter">As of Q4 2023</div>
                    </div>
                    <div>
                      <img src="\images\lattest\occupancy rate.svg" />
                    </div>
                  </div>
                  </div>
              </div>
               <div className="vdchart">
                <DatahubAreaChart userToken={userToken} data={leadsInfo.nedl_prop_id} />
               </div>
              </div>
              <div
               className={`vddatadiv demoloandiv ${leadsInfo.loan_to_value === null && leadsInfo.loan_amount === null && leadsInfo.term === null ? 'container-without-second-div' : 'container-with-second-div'}`}>
                <div className='demodiv'>
              <div className="vddatatitle">DEMOGRAPHICS</div>
              <div className="vddemographicdetails">
              <div className="vddemodiv">
              <div className="vddemostyle"><img className="dhimage" src="\images\lattest\Total Population.svg" />Total Population</div>
              <div>{leadsInfo.total_population_count !== null ? numberWithCommas(leadsInfo.total_population_count) : 'Not Available'}</div>
              </div>
              <div className="vddemodiv">
              <div className="vddemostyle"><img className="dhimage" src="\images\lattest\Population Projection.svg" />Population Projection<span style={{marginLeft:"3px",color:"grey"}}>in 5 years</span></div>
              <div>{leadsInfo.population_projection_in_5_years !== null ? numberWithCommas(leadsInfo.population_projection_in_5_years) : 'Not Available'}</div>
              </div>
              <div className="vddemodiv">
              <div className="vddemostyle"><img className="dhimage" src="\images\lattest\Population Density.svg" />Population Density (people per sq mile)</div>
              <div></div>
              </div>
              <div className="vddemodiv">
              <div className="vddemostyle"><img className="dhimage" src="\images\lattest\Population Median Age.svg" />Population Median Age<span style={{marginLeft:"3px",color:"grey"}}>in 5 years</span></div>
              <div>{leadsInfo.median_age_5_year_forecast !== null ? numberWithCommas(leadsInfo.median_age_5_year_forecast) : 'Not Available'}</div>
              </div>
              <div className="vddemodiv">
              <div className="vddemostyle"><img className="dhimage" src="\images\lattest\Total Housing.svg" />Total Housing</div>
              <div>{leadsInfo.household_count !== null ? numberWithCommas(leadsInfo.household_count) : 'Not Available'}</div>
              </div>
              <div className="vddemodiv">
              <div className="vddemostyle"><img className="dhimage" src="\images\lattest\Average People per Household.svg" />Average People per Household</div>
              <div>{leadsInfo.household_average_size !== null ? numberWithCommas(leadsInfo.household_average_size) : 'Not Available'}</div>
              </div>
              <div className="vddemodiv">
              <div className="vddemostyle"><img className="dhimage" src="\images\lattest\Median Household Income.svg" />Median Household Income</div>
              <div>{leadsInfo.median_household_income !== null ? formatCurrency(leadsInfo.median_household_income) : 'Not Available'}</div>
              </div>
              <div className="vddemodiv">
              <div className="vddemostyle"><img className="dhimage" src="\images\lattest\Employed Population.svg" />Employed Population</div>
              <div>{leadsInfo.employed_population !== null ? numberWithCommas(leadsInfo.employed_population) : 'Not Available'}</div>
              </div>
              </div>
              </div>
              {leadsInfo.loan_to_value === null && leadsInfo.loan_amount === null && leadsInfo.term === null ? null :
              <div className='loandiv'>
              <div className="vddatatitle">LOAN CHARACTERISTICS</div>
              <div className="vdloandetails">
              <div className="vdloandiv">
              <div className="vdloanstyle">
                Loan to Value</div>
              <div>{leadsInfo.loan_to_value !== null ? numberWithCommas(leadsInfo.loan_to_value) : 'Not Available'}</div>
              </div>
              <div className="vdloandiv">
              <div className="vdloanstyle">
                Loan Amount</div>
                <div>{leadsInfo.loan_amount !== null ? numberWithCommas(leadsInfo.loan_amount) : 'Not Available'}</div>
                </div>
              <div className="vdloandiv">
              <div className="vdloanstyle">
                Term</div>
                <div>{leadsInfo.term !== null ? numberWithCommas(leadsInfo.term) : 'Not Available'}</div>
                </div>
              {/* <div className="vdloandiv">
              <div className="vdloanstyle">
                Interest Rate</div>
              <div>Not Available</div>
              </div> */}
              </div>
              </div>
              }
              </div>
            </div>
           </div>
           </div>
  )
}

export default viewDetailsDatahub
