import React, { useEffect, useState, useRef } from "react";
import { Sidebar } from "primereact/sidebar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { InputText } from "primereact/inputtext";
import axios from "axios";
import { Checkbox } from "primereact/checkbox";
import { Divider } from 'primereact/divider';
import NumberFormat from "react-number-format";
import moment from "moment";
import { formatPhoneNumber } from "../../../middlewares/FormatPhoneNumber";
import { Dialog } from "primereact/dialog";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputNumber } from 'primereact/inputnumber';
import "bootstrap-icons/font/bootstrap-icons.css";
import "./leadsCards.css";
import { useNavigate } from 'react-router-dom';
import useAddAction from "../../AAANEWCOMPONENT/ActionAndSession/addAction";
import Loader from "../Loader/Loader";

const LeadsCards = ({
  fdata,
  licon,
  id,
  date,
  status,
  dropdown,
  internaldropdown,
  listedinternaldropdown,
  rbbutton,
  viewDetails,
  leadNotes,
  img,
  alt,
  header,
  address,
  lastSale,
  ownerName,
  loc,
  // leadsLatestUpdatedDate,
  progress,
  org,
  userid,
  subs_id,
  onChildChange,
  onChildLeadNoteChange,
  search,
  statusDate,
  nidropdown,
  checkedMyLeads,
  checkdataLoaded
}) => {
  const [tokenExpired, setTokenExpired] = useState(false);
  const [editPrice,setEditPrice]=useState(false);
  const [sPropertyPrice,setSPropertyPrice]=useState(null);
  const [scontactname,setSContactName]= useState(null);
  const [scontactnumber,setSContactNumber]=useState(null);
  const [scontactemail, setSContactEmail] = useState(null);
  const[extraOwnerContact,setExtraOwnerContact] = useState([]);
  const [targetedBuyer, setTargetedBuyer] = useState([]);
  const [targetedId, setTargetedId] = useState([]);
  const [viewNotesData, setViewNotesData] = useState([]);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [displayAddLeadNotes, setDisplayAddLeadNotes] = useState(false);
  const [visibleAllLeadNotes, setVisibleAllLeadNotes] = useState(false);
  const [addNoteClicked, setAddNoteClicked] = useState(false);
  const [displayNotes, setDisplayNotes] = useState(false);
  const [contacted, setContacted] = useState(false);
  const [interested, setInterested] = useState(false);
  const [interestedVal, setInterestedVal] = useState(null);
  const [notInterestedVal, setNotInterestedVal] = useState(false);
  const [visibleRight, setVisibleRight] = useState(false);
  const [visibleLeft, setVisibleLeft] = useState(false);
  const [addNotesToast, setShowAddNotesToast] = useState(false);
  const [notesToast, setNotesToast] = useState(false);
  const [idd, setIdd] = useState("");
  const toast = useRef(null);
  const isMounted = useRef(false);
  const [products, setProducts] = useState([]);
  const [contactDetails, setContactedDetails] = useState([]);
  const [LeadNotes, setLeadNotes] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [value1, setValue1] = useState("");
  const [addNotes, setAddNotes] = useState("");
  const [addLeadNotes, setAddLeadNotes] = useState("");
  const [Notes, setNotes] = useState([]);
  const [checked, setChecked] = useState();
  const [int, setInt] = useState();
  const userDetails = JSON.parse(sessionStorage.getItem("session_data"));
  const userToken = userDetails.data.token;
  const userData = JSON.parse(sessionStorage.getItem("userdata"));
  // const org = userData.data.organization;
  const fname = userData.data.name;
  const name = userData.data.firstName;
  const [showProgress,setShowProgress]=useState(false);
  const [showUpdatedDate,setShowUpdatedDate]=useState(false);
  const [edit,setEdit]=useState(false);
  const [editOwnerEmail,setEditOwnerEmail]=useState(false);
  const [secondarycontacterror,setSecondaryContactError]=useState(false);
  const [secondarycontactdetailserror,setSecondaryContactDetailsError]=useState(false);
  const [secondarycontactemailerror,setSecondaryContactEmailError]=useState(false);
  const [secondarycontactfielderror,setSecondaryContactFieldError]=useState(false);
  const [recommendedBuyersLoad,setRecommenedBuyersLoad]=useState(false);
  const [mapURL,setMapURL]=useState("");
  const [leadsInfo, setLeadsInfo] = useState({
    prop_name:"",
    prop_address: "",
    prop_city: "",
    prop_state:"",
    prop_zip:"",
    units: "",
    prop_sqrt: "",
    prop_built:"",
    last_sale: "",
    prop_id: "",
    propensity: "",
    owner_name:"",
    contact_name:"",
    owner_address:"",
    owner_city:"",
    owner_state:"",
    owner_contact_number:"",
    loan_amount:"",
    maturity_date:"",
    origination_date:"",
    time_to_mature:"",
    prop_contacted:false,
    prop_interested:false,
    notes:"",
    latitude:"",
    longitude:"",
    last_sale_amount:""
  });
  const { handleAddAction } = useAddAction(userToken);
  const pattern = new RegExp(/^\d{10,10}$/);
  const emailpattern = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
  const navigate = useNavigate();
  // useEffect(() => {
  //   isMounted.current = true;
  // }, []); 
  useEffect(()=>{
    if(tokenExpired === true){
      redirect()
    }
    },[])
    useEffect(()=>{
      progressInfo();
      UpdatedDateInfo();
    },[])
    useEffect(()=>{
      if(tokenExpired === true){
        redirect()
      }
      },[tokenExpired])
      useEffect(()=>{
        UpdatedDateInfo();
        progressInfo();
      },[search,status,progress,checkdataLoaded,checkedMyLeads]);
      useEffect(()=>{
        UpdatedDateInfo();
      },[date]);
  const redirect =()=>{
    navigate('/');
  }
  const getid = async () => {
    setRecommenedBuyersLoad(true);
    function updateBuyers(mainData, contactData) {
      return mainData.map(a => {
          if (a.buyer_name === contactData.buyers_name) {
              return {
                  ...a,
                  contacted: contactData.contacted,
                  interested: contactData.interested,
                  addnotes:contactData.addnotes
              };
          } else {
              return {
                  ...a,
                 contacted: null,
                 interested: null,
                 addnotes:null
              };
          }
      });
  }  
    setIdd(id);
    let mainArr = [];
    await axios
    .get(`${process.env.REACT_APP_BUYERSBYPROPERTYID}?property=${id}`,
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    }
    )
    .then((data) => {
        let i  = data.data;
    let jsonObject = i.map(JSON.stringify);
    let uniqueSet = new Set(jsonObject);
    let uniqueArray = Array.from(uniqueSet).map(JSON.parse);
 
      if ( data.status === 200 ){
        setRecommenedBuyersLoad(false);
        mainArr.push(uniqueArray);
        console.log(mainArr[0],"MainDetails")
        setProducts(mainArr[0])

        setTokenExpired(false)
        
    }
  })
  .catch((err) =>{
    setRecommenedBuyersLoad(false);
    // let error = err.response.data.error;
    // if(error.message === "This token has expired"){
    //  setTokenExpired(true);
    //  sessionStorage.setItem('logged',false)

    // }
  });
  await axios
  .get(`${process.env.REACT_APP_RECOMMENDED_BUYER_VIEW_DETAILS}?filter={"where":{"property_id":${id},"subs_id":${subs_id}}}`,
  {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  )
  .then((data) => {
      if (data.status === 200) {
        let i = data.data;
        if(i.length > 0){
        console.log(i,"0000")
        let rev = i.reverse();
        let res = rev[0];
        let main = mainArr[0];
        const updatedBuyers = updateBuyers(main,res);
        setProducts(updatedBuyers)
        setTokenExpired(false)
      }
      }
})
.catch((err) =>{
  // let error = err.response.data.error;
  // if(error.message === "This token has expired"){
  //  setTokenExpired(true);
  //  sessionStorage.setItem('logged',false)

  // }
});
}

const getextraownercontacts = async () => {
  console.log('iddd',id);
  await axios
  .get(`${process.env.REACT_APP_EXTRA_OWNER_CONTACTS}?filter={"where":{"org":${org},"subs_id":${subs_id},"property_id":${id},"userid":"${userid}"}}`,
  {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  }
  )
  .then((data) => {
      let i  = data.data;
    if ( data.status === 200 ){
      let rev = i.reverse();
      let res = rev[0];
      setExtraOwnerContact(res);
      setEdit(false);
      setEditOwnerEmail(false);
      setEditPrice(false);
      setTokenExpired(false)
  }
  
  })
  .catch((err) =>{
    // let error = err.response.data.error;
    // if(error.message === "This token has expired"){
    //  setTokenExpired(true);
    //  sessionStorage.setItem('logged',false)

    // }
  });
}
  const allowExpansion = (rowData) => {
    return rowData.orders.length > 0;
  };
  const formatCurrency = (value) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };
  const amountBodyTemplate = (rowData) => {
    return formatCurrency(rowData.amount);
  };
  const statusOrderBodyTemplate = (rowData) => {
    return (
      <span className={`order-badge order-${rowData.status.toLowerCase()}`}>
        {rowData.status}
      </span>
    );
  };
  const searchBodyTemplate = () => {
    return <Button icon="pi pi-search" />;
  };
  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={`images/product/${rowData.image}`}
        onError={(e) =>
          (e.target.src =
            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        }
        alt={rowData.image}
        className="product-image"
      />
    );
  };
  const priceBodyTemplate = (rowData) => {
    return formatCurrency(rowData.price);
  };
  const ratingBodyTemplate = (rowData) => {
    return <Rating value={rowData.rating} readOnly cancel={false} />;
  };
  const buyersbody = (rowData) => {
    return (
      <span>
        <p style={{ fontSize: "12px",paddingRight:0 }}><label style={{fontWeight:"400"}}>
          {rowData.owner_name !== null ? rowData.owner_name : rowData.owner_name === null && rowData.buyer_name !== null ?  rowData.buyer_name : null}</label>
          </p>
      </span>
    );
  };
  const buyeraddress = (rowData) => {
    return (
      <span>
        {rowData.owner_name !== null && rowData.owner_address !== null ? 
        <p style={{ fontSize: "12px" }}>{rowData.owner_address}<br />
                   {rowData.owner_city !== null ? rowData.owner_city : null} {rowData.owner_state !== null ? `, ${rowData.owner_state}` : null}</p>
       : rowData.owner_name === null && rowData.buyer_name !== null ?
        <p style={{ fontSize: "12px" }}>{rowData.buyer_address}<br />
          {rowData.buyer_city !== null ? rowData.buyer_city : null} {rowData.buyer_state !== null ? `, ${rowData.buyer_state}` : null}</p>
        : null }
      </span>
    );
  };
  const buyerphonenumber = (rowData) => {
    return (
      <span>
        {rowData.owner_phone_number === null && rowData.owner_email_address === null ? "Not Available" :
        <>
        <p style={{ fontSize: "12px",fontFamily:"Calibri" }}>{rowData.owner_phone_number !== null ?
formatPhoneNumber(rowData.owner_phone_number) : null}</p>
 <p style={{ fontSize: "12px",fontFamily:"Calibri", overflow: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap",
    maxWidth: "100%", display: "inline-block" }}
    title={rowData.owner_email_address !== null ? rowData.owner_email_address : null}
    >{rowData.owner_email_address !== null ?
rowData.owner_email_address : null}</p>
</>}
      </span>
    );
  };
  const handleAddNotes = (e,rowData) =>{
    e.preventDefault();
    setAddNotes("");
    setTargetedBuyer(rowData.buyer_name);
    setTargetedId(rowData.nedl_property_id_pk);
    setNotes(rowData.addnotes)
    setDisplayBasic(true);
    setAddNoteClicked(true);
  
  }
  const handleLeadAddNotes = (e) =>{
    e.preventDefault();
    setDisplayAddLeadNotes(true);  
  let addaction = {
    "userid": userid,
      "org": org,
      "subs_id": subs_id,
      "page": "Intelligent Leads",
      "widget": "Notes",
    "actions": {
      "actiontype": 'clicked',
    }
  }
  handleAddAction(addaction);
  }
  const handleViewNotes = async(e,rowData) =>{
    e.preventDefault();
    setAddNotes("");
    let buyername = rowData.buyer_name;
    let propid = rowData.nedl_property_id_pk;
    setDisplayNotes(true);
  let addaction = {
    "userid": userid,
    "org": org,
    "subs_id": subs_id,
    "page": "Intelligent Leads",
    "widget": "View Notes",
    "actions": {
      "actiontype": 'clicked',
    }

  }
  handleAddAction(addaction);
    await axios
    .get(
      `${process.env.REACT_APP_RECOMMENDED_BUYER_VIEW_DETAILS}?filter={"where":{"property_id":${propid},"buyers_name":"${buyername}","subs_id":${subs_id}}}`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
    .then(async (data) => {
      if (data.status === 200) {
        let d = data.data;
        setViewNotesData(d.reverse());
        setTokenExpired(false)
      }
  })
  .catch((err) =>{
    // let error = err.response.data.error;
    // if(error.message === "This token has expired"){
    //  setTokenExpired(true);
    //  sessionStorage.setItem('logged',false)

    // }
  });
  }
  const handleViewLeadNotes = async(id) =>{
   let addaction = {
    "userid": userid,
    "org": org,
    "subs_id": subs_id,
    "page": "Intelligent Leads",
    "widget": "View Lead Notes",
    "actions": {
      "actiontype": 'clicked',
    }

  }
 handleAddAction(addaction);
    await axios
    .get(
      `${process.env.REACT_APP_LEADS_NOTES}?filter={"where":{"org":${org},"subs_id":${subs_id},"property_id":${id}}}`,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
    .then((data) => {
      if (data.status === 200) {
        let d = data.data;
        console.log(d,"datatata")
      let fd =  d.sort(function(a,b){
          return new Date(a.inserted_on).getTime() - new Date(b.inserted_on).getTime();});
        setLeadNotes(fd.reverse());
        setTokenExpired(false)
      }
    })
    .catch((err) =>{
      // let error = err.response.data.error;
      // if(error.message === "This token has expired"){
      //  setTokenExpired(true);
      //  sessionStorage.setItem('logged',false)

      // }
    });
  }
  const notesdate = (rowData) => {
    console.log(rowData,"checkviewDetails")
    return (
      <span>  
        <p style={{ fontSize: "12px",fontFamily:"Calibri" }}>{moment(rowData.inserted_on).format("DD/MM/YYYY")}</p>
        <p style={{ fontSize: "12px",fontFamily:"Calibri" }}>{moment(rowData.inserted_on).format("hh:mm:ss A")}</p>
      </span>
    );
  };
  const leadnotesdate = (rowData) => {
    return (
      <span>  
        <p style={{ fontSize: "12px",fontFamily:"Calibri" }}>{moment(rowData.inserted_on).format("DD/MM/YYYY")}</p>
        <p style={{ fontSize: "12px",fontFamily:"Calibri" }}>{moment(rowData.inserted_on).format("hh:mm:ss A")}</p>
      </span>
    );
  };
  const notesdetails = (rowData) => {
  //   let f = rowData.firstname?.charAt(0).toUpperCase() +rowData.firstname?.slice(1).toLowerCase();
  //   let l = rowData.lastname?.charAt(0).toUpperCase() +rowData.lastname?.slice(1).toLowerCase();
  //  let user = `${f} ${l}`;
    return (
      <span>  
        <p style={{ fontSize: "12px",fontFamily:"Calibri" }}>{rowData.addnotes}</p>
        {/* <div style={{color:"#ED7D31", fontSize: "10px",fontFamily:"Calibri",fontWeight:"400" }}>{user}</div> */}
      </span>
    );
  };
  const leadnotesdetails = (rowData) => {
  //   let f = rowData.firstname?.charAt(0).toUpperCase() +rowData.firstname?.slice(1).toLowerCase();
  //   let l = rowData.lastname?.charAt(0).toUpperCase() +rowData.lastname?.slice(1).toLowerCase();
  //  let user = `${f} ${l}`; 
      return (
      <span>  
        <p style={{ fontSize: "12px",fontFamily:"Calibri" }}>{rowData.notes}</p>
        {/* <div style={{color:"#ED7D31", fontSize: "10px",fontFamily:"Calibri",fontWeight:"400"}}>{user}</div> */}
      </span>
    );
  };
  const leadnotesviewdetails = (rowData) => {
    // let f = rowData.firstname?.charAt(0).toUpperCase() +rowData.firstname?.slice(1).toLowerCase();
    // let l = rowData.lastname?.charAt(0).toUpperCase() +rowData.lastname?.slice(1).toLowerCase();
    let time = moment(rowData.inserted_on).format("hh:mm A");
    let date = moment(rowData.inserted_on).format("DD/MM/YYYY");
  //  let user = `${f} ${l}`;  
     return (
      <span>  
        <p style={{ fontSize: "12px",fontFamily:"Calibri" }}>{rowData.notes}</p>
        <div style={{color:"#ED7D31", fontSize: "10px",fontFamily:"Calibri",fontWeight:"400",display:"flex" ,justifyContent:"space-between"}}>
          {/* <div>{user}</div> */}
          <div style={{display:"flex",justifyContent:"flex-end",gap:"5px"}}>
          <div>{date}</div>
          <div>{time}</div>
          </div>
          {/* {user}&nbsp;&nbsp;{date}&nbsp;&nbsp;{time} */}
          </div>
      </span>
    );
  };
  const addnotes = (rowData) => { 
    return (
        <span>
          
          <p style={{ fontSize: "12px" }}>{rowData.addnotes}</p>
        </span>
      );
  };
const handleContacted = (e,rowData) =>{
  console.log(rowData,"000")
  setAddNotes("");
  setTargetedBuyer(rowData.buyer_name);
  setTargetedId(rowData.nedl_property_id_pk);
  setContacted(e.target.checked);
  setDisplayBasic(true);
  setNotes(rowData.addnotes);
  setInterested(e.value.interested)
}
const handleInterested = (e,rowData) =>{
  setAddNotes("");
  setTargetedBuyer(rowData.buyer_name);
  setTargetedId(rowData.nedl_property_id_pk);
  setContacted(true);
  setInterestedVal(true);
  setNotes(rowData.addnotes);
  setInterested(e.target.checked);
  setDisplayBasic(true);
}
const handleNotInterested = (e,rowData) =>{
  setAddNotes("");
  setTargetedBuyer(rowData.buyer_name);
  setTargetedId(rowData.nedl_property_id_pk);
  setContacted(true);
  setInterestedVal(false);
  setNotes(rowData.addnotes);
  setInterested(e.target.checked);
  console.log(e.target.checked,"not interested")
  setDisplayBasic(true);
}
  const acceptFunc = async (targetedBuyer,targetedId,contacted,interested,interestedVal) => {
    console.log(addNoteClicked,targetedBuyer,targetedId,contacted,interested,interestedVal,"val")
    if(addNotes === ""){
      setNotesToast(true);
      setTimeout(function () {
        setNotesToast(false)
      }, 5000);
    }
  
    if(addNoteClicked === false && contacted === false){
      let statuses ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id":targetedId*1,
        "buyers_name":targetedBuyer,
        "contacted":false,
        "addnotes":addNotes
      }
      await axios
        .post(`${process.env.REACT_APP_BUYERCONTACT}/`, statuses,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
          setAddNotes("");
          setDisplayBasic(false);
          getid(targetedId)
          console.log("Successfull", data.data);
        }
         
        });
      }
     
    if(addNoteClicked === false && contacted === true && interested === undefined ){
    let statuses ={
      "subs_id":subs_id,
      "userid":userid,
      "org": org,
      "property_id":targetedId*1,
      "buyers_name":targetedBuyer,
      "contacted":true,
      "addnotes":addNotes
    }

    await axios
      .post(`${process.env.REACT_APP_BUYERCONTACT}/`, statuses,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
      )
      .then((data) => {
      
      if (data.status === 200){
        setAddNotes("");
        setDisplayBasic(false);
        getid(targetedId)
        console.log("Successfull", data.data);
      }
       
      });
    }
    if(addNoteClicked === false && contacted === true && interested === true){
      let statuses ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id":targetedId*1,
        "buyers_name":targetedBuyer,
        "contacted":true,
        "interested":interestedVal,
        "addnotes":addNotes
      }
  
      await axios
        .post(`${process.env.REACT_APP_BUYERCONTACT}/`, statuses,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
          setAddNotes("");
          setDisplayBasic(false);
          getid(targetedId)
          console.log("Successfull", data.data);
        }
         
        });
    }
    if(addNoteClicked === false && contacted === true && interested === false){
      let statuses ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id":targetedId*1,
        "buyers_name":targetedBuyer,
        "contacted":true,
        "addnotes":addNotes
      }
  
      await axios
        .post(`${process.env.REACT_APP_BUYERCONTACT}/`, statuses,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
          setAddNotes("");
          setDisplayBasic(false);
          getid(targetedId)
          console.log("Successfull", data.data);
        }
         
        });
    }

    if(addNoteClicked === true && contacted === false){
      let statuses ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id":targetedId*1,
        "buyers_name":targetedBuyer,
        "contacted":false,
        "addnotes":addNotes
      }
  
      await axios
        .post(`${process.env.REACT_APP_BUYERCONTACT}/`, statuses,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
          setAddNotes("");
          setDisplayBasic(false);
          setAddNoteClicked(false);
          getid(targetedId);
          console.log("Successfull", data.data);
        }
         
        });
    }
    if(addNoteClicked === true && contacted === true && interested === undefined ){
      let statuses ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id":targetedId*1,
        "buyers_name":targetedBuyer,
        "contacted":true,
        "addnotes":addNotes
      }
  
      await axios
        .post(`${process.env.REACT_APP_BUYERCONTACT}/`, statuses,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
          setAddNotes("");
          setDisplayBasic(false);
          setAddNoteClicked(false);
          getid(targetedId);
          console.log("Successfull", data.data);
        }
         
        });
    }
    if(addNoteClicked === true && contacted === true && interested === true  ){
      let statuses ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id":targetedId*1,
        "buyers_name":targetedBuyer,
        "contacted":true,
        "interested":interestedVal,
        "addnotes":addNotes
      }
  
      await axios
        .post(`${process.env.REACT_APP_BUYERCONTACT}/`, statuses,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
          setAddNotes("");
          setDisplayBasic(false);
          setAddNoteClicked(false);
          getid(targetedId);
          console.log("Successfull", data.data);
        }
         
        });
    }
    if(addNoteClicked === true && contacted === true && interested === false){
      let statuses ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id":targetedId*1,
        "buyers_name":targetedBuyer,
        "contacted":true,
        "addnotes":addNotes
      }
  
      await axios
        .post(`${process.env.REACT_APP_BUYERCONTACT}/`, statuses,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
          setAddNotes("");
          setDisplayBasic(false);
          setAddNoteClicked(false);
          getid(targetedId);
          console.log("Successfull", data.data);
        }
         
        });
    }
     
 };
const acceptLeadFunc = async () =>{
  onChildChange(false);
  onChildLeadNoteChange(false);
  if(addLeadNotes === ""){
    setNotesToast(true);
    setTimeout(function () {
      setNotesToast(false)
    }, 5000);

  }
  if(addLeadNotes !== ""){
    let notes ={
      "property_id": id*1,
 "userid": userid,
 "notes": addLeadNotes,
 "org": org,
 "subs_id": subs_id

     }

    await axios
      .post(`${process.env.REACT_APP_LEADS_NOTES}/`, notes,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
      )
      .then((data) => {
      
      if (data.status === 200){
        setAddLeadNotes("");
        setShowProgress(true);
        setShowUpdatedDate(true);
        onChildChange(true);
        onChildLeadNoteChange(true);
        setDisplayAddLeadNotes(false);

        console.log("Successfull", data.data);
      }
       
      });
    }
    handleViewLeadNotes(id);
}
  const addNotesDialogue = () => {
    return <>
      <Dialog className="addNotesDialog" header="Confirmation" visible={displayBasic} draggable={false} onHide={() => setDisplayBasic(false)}>
       <>
       <div className="alignnotes">
        <p className="addchangestyle">Add Notes</p>
        <div className="enternotes">
        <InputText required value={addNotes} onChange={(e) => setAddNotes(e.target.value)} /><br />
          </div>
        </div>
        {notesToast ?
                <>
                <p className="error">Notes cannot be empty</p>
                </>
                : null}
       </>
        <div className="dealfooter">
        <Button className="dealbtn" onClick={() => acceptFunc(targetedBuyer,targetedId,contacted,interested,interestedVal,Notes)}>Save</Button>
        <Button className="dealbtn" onClick={() => setDisplayBasic(false)}>Cancel</Button>
        </div>
      </Dialog>
    </>
  }
  const add = (rowData) => {
    return(
      <>
      <div style={{display:"flex",gap:"5px"}}>
      <p>Notes</p>
      <Button onClick={(e) => handleAddNotes(e,rowData)} className="addbtn">Add</Button>
      <Button onClick={(e) => handleViewNotes(e,rowData)} className="addbtn">View</Button>
      </div>

      </>
    );

  }
  const rowExpansionTemplate = (data) => {
    let result =[];
  let a = products.find(item => item.nedl_property_id_pk === data.nedl_property_id_pk && item.buyer_name === data.buyer_name)
   result.push(a)
    console.warn("data", result);
    return (
      <div className="orders-subtable">
        <DataTable value={result} dataKey="buyer_name" className="expandtable">
          <Column style={{ width: "4em", border: "none" }} />
          <Column
            style={{  border: "none" }}
            body={buyeraddress}
            header="Address"
          />
          {/* <Column
            style={{ border: "none" }}
            field="buyer_segment"
            header="Persona"
          /> */}
          <Column
            style={{ border: "none" }}
            header={add(data)}
            body={addnotes}
          />
        </DataTable>
      </div>
    );
  };
const toggleDetails = (pid) => {
  let newData =[]
  let a = fdata.find(item => item.nedl_property_id_pk*1 === pid)
   newData.push(a)
    setVisibleLeft(true);
    handleViewLeadNotes(id);
    getextraownercontacts();
    setSPropertyPrice(null);
    setSContactName(null);
    setSContactNumber(null);
    setSContactEmail(null);
  let addaction = {
    "userid": userid,
    "org": org,
    "subs_id": subs_id,
    "page": "Intelligent Leads",
    "widget": "View Details",
    "actions": {
      "actiontype": 'clicked',
    }

  }
handleAddAction(addaction);
    newData.map((d)=>{
      setLeadsInfo({
        prop_name:d.nedl_property_name,
        prop_address: d.address,
        prop_city: d.city,
        prop_state:d.state,
        prop_zip:d.zip,
        units: d.units_count,
        prop_sqrt: d.building_sq_ft,
        last_sale: d.last_sale_date,
        propensity: d.lead_type,
        prop_built:d.year_built,
        prop_id: d.nedl_property_id_pk,
        owner_name:d.owner_name,
        contact_name:d.owner_contact_name,
        owner_address:d.owner_address,
        owner_city:d.owner_city,
        owner_state:d.owner_state,
        owner_contact_number:d.owner_phone_number,
        loan_amount:d.loan_amount,
        maturity_date:d.loan_maturity_date,
        origination_date:d.loan_origination_date,
        time_to_mature:d.time_to_mature,
        latitude:d.latitude,
        longitude:d.longitude,
        last_sale_amount:d.last_sale_amount
    })
    });
    getmapurl(newData);
  }
  const toggleLeadsNotes = (id) => {
    setVisibleLeft(false);
    // onChildChange(false);
    // onChildLeadNoteChange(false);
     handleViewLeadNotes(id);
      setVisibleAllLeadNotes(true);
    }
  const toggleRecommendedBuyers = (id) => {
    let newRBData =[]
    let a = fdata.find(item => item.nedl_property_id_pk === id)
     newRBData.push(a)
      setVisibleRight(true);
  let addaction = {
    "userid": userid,
    "org": org,
    "subs_id": subs_id,
    "page": "Intelligent Leads",
    "widget": "Recommended Buyers",
    "actions": {
      "actiontype": 'clicked',
    }
  }
handleAddAction(addaction);
      newRBData.map((d)=>{
        setLeadsInfo({
          prop_name:d.nedl_property_name,
          prop_address: d.address,
          prop_city: d.city,
          prop_state:d.state,
          prop_zip:d.zip,
          units: d.units_count,
          prop_sqrt: d.building_sq_ft,
          last_sale: d.last_sale_date,
          propensity: d.lead_type,
          prop_built:d.year_built,
          prop_id: d.nedl_property_id_pk,
          prop_contacted:d.contacted,
          prop_interested:d.interested,
          notes:d.addnotes
      })
      getid(leadsInfo.prop_id);      
      });
    }
    const buyerContacted = (rowData) =>{
      return (
        <div className="field-checkbox">
         <Checkbox
          inputId="binary"
            value={rowData}
            checked={rowData.contacted}
            onChange={(e) => handleContacted(e,rowData)}
          />
          <label style={{ margin: "0px 3px" }} htmlFor="binary">
            {" "}
            Yes
          </label>
        </div>
      );
    }
    const buyerInterested = (rowData) =>{
      return (
            <div className="field-checkbox">
            <Checkbox
              inputId="yes"
              value={interestedVal}
              checked={rowData.interested === true ? true : false}
              onChange={(e) => handleInterested(e,rowData)}
              />
            <label style={{ margin: "0px 3px" }} htmlFor="yes">
              {" "}
              Yes
            </label>
            <Checkbox inputId="no" 
             value={interestedVal}
            checked={rowData.interested === false  ? true : false} 
            onChange={(e) => handleNotInterested(e,rowData)}
            />
            <label style={{ margin: "0px 3px" }} htmlFor="no">
              {" "}
              No
            </label>
          </div>
      );
    }
    const addLeadNotesDialogue = () => {
      return <>
        <Dialog className="leadNotesDialog" header="Notes" visible={displayAddLeadNotes} draggable={false}  onHide={() => setDisplayAddLeadNotes(false)}>
         <>
         <div className="alignnotes">
          <p className="addchangestyle">Add Notes</p>
          <div className="enternotes">
          <InputText required value={addLeadNotes} onChange={(e) => setAddLeadNotes(e.target.value)} /><br />
            </div>
          </div>
          {notesToast ?
                  <>
                  <p className="error">Notes cannot be empty</p>
                  </>
                  : null}
         </>
          <div className="dealfooter">
          <Button className="dealbtn" onClick={() => acceptLeadFunc()}>Save</Button>
          <Button className="dealbtn" onClick={() => setDisplayAddLeadNotes(false)}>Cancel</Button>
          </div>
        </Dialog>
      </>
    }
    const viewNotesDialogue = () => {
      return <>
        <Dialog className="viewNotesDialog" header="Notes" visible={displayNotes} draggable={false} onHide={() => setDisplayNotes(false)}>
         <>
         <div className="alignnotes">
         <DataTable className="rbbuyerstb"
                value={viewNotesData}
                responsiveLayout="scroll"
                resizableColumns
              >
                <Column header="Notes" body={notesdetails}/>
                <Column header="Added On" body={notesdate} />
              </DataTable>
          </div>
         </>
        </Dialog>
      </>
    }
    const viewAllLeadsNotesDialogue = (rowData) => {
      return <>
        <Dialog className="viewAllLeadsNotesDialog" header="Notes" visible={visibleAllLeadNotes} draggable={false} onHide={() => setVisibleAllLeadNotes(false)}>
         <>
         <div className="leadbtndiv">
        
         <Button className="leadsaddnotesbtn" onClick={(e) => handleLeadAddNotes(e,rowData)}>Add Notes</Button>
         </div>
         <DataTable className="rbbuyerstb"
                value={LeadNotes}
                responsiveLayout="scroll"
                resizableColumns
              >
                <Column header="Notes" body={leadnotesdetails}/>
                <Column header="Added On"  body={leadnotesdate}/>
              </DataTable>
         </>
        </Dialog>
      </>
    }
    const progressInfo =()=>{
      if(progress > "0"){
        setShowProgress(true);
      }else setShowProgress(false);
    }
    const UpdatedDateInfo =()=>{
      if(date !== undefined && date !== null){
        setShowUpdatedDate(true);
      }else setShowUpdatedDate(false);
    }
  const updateContactDetails = async(propid)=>{
    setSecondaryContactDetailsError(false);
    setSecondaryContactError(false);
    setSecondaryContactFieldError(false);
    setSecondaryContactEmailError(false);

if(extraOwnerContact !== undefined && extraOwnerContact.list_price !== null){
  const processListPrice = (listPrice) => {
    const cleanedValue = listPrice.replace(/[^0-9.]/g, '');
    return Math.floor(parseFloat(cleanedValue));
  };
const formattedPrice = processListPrice(extraOwnerContact.list_price);
console.log(formattedPrice,"formattedPrice")
    if(scontactname !== null && scontactnumber !== null && pattern.test(scontactnumber) && scontactemail === null && extraOwnerContact.contact_email === null ){
      let extracontact ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id": propid*1,
        "contact_name": scontactname,
        "contact_number": scontactnumber,
        "list_price":formattedPrice*1
      }
      await axios
        .post(`${process.env.REACT_APP_EXTRA_OWNER_CONTACTS}/`, extracontact,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
         getextraownercontacts();
         setSContactName(null);
         setSContactNumber(null);
        }
         
        });
    }
    if(scontactname !== null && scontactnumber !== null && pattern.test(scontactnumber) && scontactemail !== null && emailpattern.test(scontactemail) && extraOwnerContact.contact_email === null){
      let extracontact ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id": propid*1,
        "contact_name": scontactname,
        "contact_number": scontactnumber,
        "contact_email":scontactemail,
        "list_price":formattedPrice*1
      }
      await axios
        .post(`${process.env.REACT_APP_EXTRA_OWNER_CONTACTS}/`, extracontact,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
         getextraownercontacts();
         setSContactName(null);
         setSContactNumber(null);
         setSContactEmail(null);
        }
         
        });
    }
    if(scontactname !== null && scontactnumber !== null && pattern.test(scontactnumber) && extraOwnerContact !== undefined && extraOwnerContact.contact_email !== null){
      let extracontact ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id": propid*1,
        "contact_name": scontactname,
        "contact_number": scontactnumber,
        "contact_email":extraOwnerContact.contact_email,
        "list_price":formattedPrice*1
      }
      await axios
        .post(`${process.env.REACT_APP_EXTRA_OWNER_CONTACTS}/`, extracontact,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
         getextraownercontacts();
         setSContactName(null);
         setSContactNumber(null);
        }
         
        });
    }
    if(edit === false && editOwnerEmail === true && scontactname === null && scontactnumber === null && scontactemail !== null && emailpattern.test(scontactemail)){
      let extracontact ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id": propid*1,
        "contact_name": extraOwnerContact.contact_name,
        "contact_number": extraOwnerContact.contact_number,
        "contact_email":scontactemail,
        "list_price":formattedPrice*1
      }
      await axios
        .post(`${process.env.REACT_APP_EXTRA_OWNER_CONTACTS}/`, extracontact,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
         getextraownercontacts();
         setSContactName(null);
         setSContactNumber(null);
         setSContactEmail(null);
        }
         
        });
    }
    if(sPropertyPrice !== null && extraOwnerContact?.contact_email !== null){
      let extracontact ={
      "subs_id":subs_id,
      "userid":userid,
      "org": org,
      "property_id": propid*1,
      "contact_name": extraOwnerContact.contact_name,
      "contact_number": extraOwnerContact.contact_number,
      "contact_email":extraOwnerContact.contact_email,
      "list_price":sPropertyPrice*1,
    }
    await axios
      .post(`${process.env.REACT_APP_EXTRA_OWNER_CONTACTS}/`, extracontact,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
      )
      .then((data) => {
      
      if (data.status === 200){
        getextraownercontacts();
        setSPropertyPrice(null);
      }
       
      });
    }
    if(sPropertyPrice !== null && extraOwnerContact?.contact_name !== null && extraOwnerContact?.contact_email === null){
      let extracontact ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id": propid*1,
        "contact_name": extraOwnerContact.contact_name,
        "contact_number": extraOwnerContact.contact_number,
        "list_price":sPropertyPrice*1
      }
      await axios
        .post(`${process.env.REACT_APP_EXTRA_OWNER_CONTACTS}/`, extracontact,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
         getextraownercontacts();
         setSPropertyPrice(null);
        }
         
        });
    
    }
    if(sPropertyPrice !== null && extraOwnerContact?.contact_name === null){
      let extracontact ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id": propid*1,
        "list_price":sPropertyPrice*1
      }
      await axios
        .post(`${process.env.REACT_APP_EXTRA_OWNER_CONTACTS}/`, extracontact,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
         getextraownercontacts();
         setSPropertyPrice(null);
        }
         
        });
      
    
    }
  }
  if(extraOwnerContact === undefined){
    if(scontactname !== null && scontactnumber !== null && pattern.test(scontactnumber) && scontactemail === null){
      let extracontact ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id": propid*1,
        "contact_name": scontactname,
        "contact_number": scontactnumber,
      }
      await axios
        .post(`${process.env.REACT_APP_EXTRA_OWNER_CONTACTS}/`, extracontact,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
         getextraownercontacts();
         setSContactName(null);
         setSContactNumber(null);
        }
         
        });
    }
    if(scontactname !== null && scontactnumber !== null && pattern.test(scontactnumber) && scontactemail !== null && emailpattern.test(scontactemail)){
      let extracontact ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id": propid*1,
        "contact_name": scontactname,
        "contact_number": scontactnumber,
        "contact_email":scontactemail,
      }
      await axios
        .post(`${process.env.REACT_APP_EXTRA_OWNER_CONTACTS}/`, extracontact,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
         getextraownercontacts();
         setSContactName(null);
         setSContactNumber(null);
         setSContactEmail(null);
        }
         
        });
    }
    if(sPropertyPrice !== null){
      let extracontact ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id": propid*1,
        "list_price":sPropertyPrice*1
      }
      await axios
        .post(`${process.env.REACT_APP_EXTRA_OWNER_CONTACTS}/`, extracontact,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
         getextraownercontacts();
         setSPropertyPrice(null);
        }
         
        });
      
    
    }
  }
  if(extraOwnerContact !== undefined && extraOwnerContact.list_price === null){
    if(scontactname !== null && scontactnumber !== null && pattern.test(scontactnumber) && extraOwnerContact.contact_email !== null){
      let extracontact ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id": propid*1,
        "contact_name": scontactname,
        "contact_number": scontactnumber,
        "contact_email":extraOwnerContact.contact_email,
      }
      await axios
        .post(`${process.env.REACT_APP_EXTRA_OWNER_CONTACTS}/`, extracontact,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
         getextraownercontacts();
         setSContactName(null);
         setSContactNumber(null);
        }
         
        });
    }
    if(edit === false && editOwnerEmail === true && scontactname === null && scontactnumber === null){
      let extracontact ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id": propid*1,
        "contact_name": extraOwnerContact.contact_name,
        "contact_number": extraOwnerContact.contact_number,
        "contact_email":scontactemail,
      }
      await axios
        .post(`${process.env.REACT_APP_EXTRA_OWNER_CONTACTS}/`, extracontact,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
         getextraownercontacts();
         setSContactName(null);
         setSContactNumber(null);
         setSContactEmail(null);
        }
         
        });
    }
    if(sPropertyPrice !== null && extraOwnerContact?.contact_email !== null){
      let extracontact ={
      "subs_id":subs_id,
      "userid":userid,
      "org": org,
      "property_id": propid*1,
      "contact_name": extraOwnerContact.contact_name,
      "contact_number": extraOwnerContact.contact_number,
      "contact_email":extraOwnerContact.contact_email,
      "list_price":sPropertyPrice*1,
    }
    await axios
      .post(`${process.env.REACT_APP_EXTRA_OWNER_CONTACTS}/`, extracontact,
      {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }
      )
      .then((data) => {
      
      if (data.status === 200){
        getextraownercontacts();
        setSPropertyPrice(null);
      }
       
      });
    }
    if(sPropertyPrice !== null && extraOwnerContact?.contact_email === null){
      let extracontact ={
        "subs_id":subs_id,
        "userid":userid,
        "org": org,
        "property_id": propid*1,
        "contact_name": extraOwnerContact.contact_name,
        "contact_number": extraOwnerContact.contact_number,
        "list_price":sPropertyPrice*1
      }
      await axios
        .post(`${process.env.REACT_APP_EXTRA_OWNER_CONTACTS}/`, extracontact,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
        )
        .then((data) => {
        
        if (data.status === 200){
         getextraownercontacts();
         setSPropertyPrice(null);
        }
         
        });
    
    }
  } 

 if(edit === true && scontactemail === null && scontactnumber !== null && pattern.test(scontactnumber) && scontactname === null || edit === true && scontactemail === null && scontactnumber === null && scontactname !== null){
  setSecondaryContactDetailsError(true);
    setTimeout(function () {
      setSecondaryContactDetailsError(false)
    }, 3000); 
    
  }
if(scontactname !== null && scontactnumber !== null && !pattern.test(scontactnumber) && scontactemail === null){
 setSecondaryContactError(true);
  setTimeout(function () {
    setSecondaryContactError(false)
  }, 3000); 
}
if(pattern.test(scontactnumber) && emailpattern.test(scontactemail) && edit === true && editOwnerEmail === true && scontactname === null || edit === true && editOwnerEmail === true && scontactnumber === null && emailpattern.test(scontactemail)|| edit === true && editOwnerEmail === true && scontactemail === null && pattern.test(scontactnumber)){
     setSecondaryContactFieldError(true);
  setTimeout(function () {
    setSecondaryContactFieldError(false)
  }, 3000);
}
if(scontactnumber !== null && !pattern.test(scontactnumber)){
  setSecondaryContactError(true);
  setTimeout(function () {
    setSecondaryContactError(false)
  }, 3000);
}
if(edit === false && editOwnerEmail === true && scontactname === null && scontactnumber === null && scontactemail !== null && !emailpattern.test(scontactemail) || scontactemail !== null && !emailpattern.test(scontactemail)){
  setSecondaryContactEmailError(true);
  setTimeout(function () {
    setSecondaryContactEmailError(false)
  }, 3000); 
}
  }
const getmapurl = (newData) =>{ 
  let prop = newData?.map(d => d.nedl_property_name !== null ? d.nedl_property_name : null);
 let address = newData.map(d=> d.address !== null ? d.address : null);
 let lat = newData?.map(d => d.latitude !== null ? d.latitude : null);
 let longg = newData?.map(d=> d.longitude !== null ? d.longitude : null);
  let mapurl = '';
  if (prop !== null && prop !== undefined) {
    let p = prop.toString();
    let formattedProp = p.replace(/\s/g, '+');
    mapurl = `${formattedProp}`
  }
  if (address !== null && address !== undefined && prop === null) {
    let a = address.toString();
    let formattedAddress = a.replace(/\s/g, '+') ;
    mapurl = `${formattedAddress}`
  }
  if (address !== null && address !== undefined && prop !== null) {
    let a = address.toString();
    let formattedAddress = a.replace(/\s/g, '+') ;
    mapurl = `${mapurl},${formattedAddress}`
  }
  if(lat!== null && lat !== undefined && longg !== null && longg !== undefined )
  {
    mapurl = `${mapurl}&center=${lat},${longg}`
  }
  setMapURL(mapurl);
}
const addPhoneAction = (data) => {
   let addaction = {
    "userid": userid,
    "org": org,
    "subs_id": subs_id,
    "page": "Intelligent Leads",
    "widget": "View Details",
    "actions": {
      "actiontype": 'call',
      "property_id":data.property_id,
      "contact_name":data.contact_name,
      "contact_number":data.contact_number,
    }

  }
handleAddAction(addaction);
  }
  const addMailAction = (data) => {
    let addaction = {
      "userid": userid,
      "org": org,
      "subs_id": subs_id,
      "page": "Intelligent Leads",
      "widget": "View Details",
     "actions": {
       "actiontype": 'mail',
       "property_id":data.property_id,
       "contact_name":data.contact_name,
       "contact_email":data.contact_email,
     }
 
   }
handleAddAction(addaction);
   }
const editAll = () =>{
  setEdit(true);
  setEditOwnerEmail(true);
}
  return (
    <>
      <div className="leadscard IntelLeads"
      // style={{ boxShadow: ` 0px 3px 5px ${progressInfo}` }}
      >
          {/* {showProgress === true && status === "LEAD" && checkdataLoaded === true && checkedMyLeads === true? 
            <>
            <b className="progress">
           <span className="tooltiptext">In Progress</span></b>
            </>
            : status === "LEAD" &&checkdataLoaded === true && checkedMyLeads === false ?
            null: null
            } */}
               {showUpdatedDate === true && checkdataLoaded === true && status === "LEAD" ? 
                <b className="progress">
                <span className="tooltiptext">In Progress</span></b>
                 
          :null  
          }
        <div className="header">
          <div className="main-content">
             {/* {progress > "0" ? 
            <>
             <b className="progress">
           <span className="tooltiptext">In Progress</span>
             </b>
            </>
            :null} */}
            <p className="title">{header}</p>
            {/* <p className="title lastSale">{id}</p>   */}
            {/* <p className="title lastSale">{date}</p>   */}
            <div className="location">
              <img className="location-icon" src={licon} alt={loc}></img>
              <p className="address">{address}</p>
            </div>
            <p className="lastSale"><b>Last Sale Date:</b> {lastSale !== "Invalid date" ? lastSale : 'Not Available'}</p>
            {/* {leadsLatestUpdatedDate !== null && status === "LEAD" ? 
          <p className="lastSale"><b>Updated On:</b> {moment(leadsLatestUpdatedDate).format("MM/DD/YYYY")}</p>
          :null  
          } */}
            {/* {showUpdatedDate === true  && status !== "LEAD" ? 
          <p className="lastSale"><b>Updated On:</b> {moment(date).format("MM/DD/YYYY")}</p>
          :null  
          } */}
             {showUpdatedDate === true ? 
          <p className="lastSale"><b>Updated On:</b> {moment(date).format("MM/DD/YYYY")}</p>
          :null  
          }
            <p className="ownerName">
              <b>Owner Name:</b> {ownerName}
            </p>
          </div>
          <div className="side-content">
            {status ==="LEAD" ?  <img className="image" src={img} alt={alt} /> 
            :
             null
              }
                {statusDate !== undefined  && status !== "LEAD" ? 
          <div className="image dateStatus">Dispositioned <br />
          {moment(statusDate).format("MM/DD/YYYY") === moment().format("MM/DD/YYYY") ? "Today" : moment(statusDate).fromNow()}</div>
          :null  
          }

            <button className="viewDetails" 
          onClick={
            () => toggleDetails(id*1)}
            >
            {viewDetails}
          </button>
          <button className="viewDetails" 
          onClick={
            () => toggleLeadsNotes(id*1)}
            >
            {leadNotes}
          </button>
            {/* <p className="viewDetails">{viewDetails}</p> */}
          </div>
        </div>
        <div className="content">
          <div className="dropdown">
          <label className="leadstatuslabel">Current Status</label>
          {status !== "NOT INTERESTED" ?
          <div>{dropdown}</div>
           :
           <div>{nidropdown}</div>
           }
          </div>
          {status === "INTERESTED" ? <>
           <div className="dropdown">
          <label className="leadstatuslabel">Financial Status</label>
          <div>{internaldropdown}</div>
          </div>
          </>
          :status === "LISTED" ?
          <>
           <div className="dropdown">
          <label className="leadstatuslabel">Listed</label>
          <div>{listedinternaldropdown}</div>
          </div>
          </> :<div className="dropdown"></div> }
          <button className="rbbutton" 
          onClick={
            () => toggleRecommendedBuyers(id)}
            // { getid();setVisibleRight(true);}}
            >
            {rbbutton}
          </button>
        </div>
        <Sidebar
          visible={visibleRight}
          position="right"
          onHide={() => setVisibleRight(false)}
          className="recommendedBuyerSlider"
        >
          <div className="recommended">
            <h3 className="">Recommended Buyers</h3>
            <div className="header">
              <div className="main-content">
                <p className="title">{leadsInfo.prop_name}</p>
                <div className="location">
                  <img className="location-icon" src={licon}></img>
                  <p className="address">{leadsInfo.prop_address}</p>
                </div>
                <p className="state">{`${leadsInfo.prop_city}, ${leadsInfo.prop_state}, ${leadsInfo.prop_zip}`}</p>
                <p className="lastSale">{leadsInfo.last_sale !== null ? moment(leadsInfo.last_sale).format("MM/DD/YYYY") : null}</p>
              </div>
              {status === "LEAD" ?
              <div className="side-content">
                <img className="image" src={img} alt={alt} />
              </div>
               : null}
            </div>
            <div className="card buyers">
              {recommendedBuyersLoad === true ? <Loader /> :
              <>
              {products.length > 0 ?
              <DataTable className="buyerstb"
                value={products}
                expandedRows={expandedRows}
                onRowToggle={(e) => setExpandedRows(e.data)}
                responsiveLayout="scroll"
                resizableColumns
                columnResizeMode="expand"
                rowExpansionTemplate={rowExpansionTemplate}
                dataKey="buyer_name"
              >
                <Column expander={true} style={{width:"35px",padding:"1rem 1px 1rem 0px" }} />
                <Column header="Buyer" style={{minWidth:"300px",paddingRight:0 }} body={buyersbody} />
                <Column header="Contact" style={{minWidth:"300px",paddingRight:0 }} body={buyerphonenumber} />
                <Column header="Buyer Contacted" body={buyerContacted} />
                <Column header="Buyer Interested" body={buyerInterested} />
              </DataTable>
              : <label className="recbNotAvailableLable">Recommended Buyers will be updated soon. Please check back after some time.</label>
              }
              </>
              }
            </div>
          </div>
        </Sidebar>
        <Sidebar style={{ width: "30%" }} visible={visibleLeft} position="right" onHide={() => setVisibleLeft(false)} baseZIndex={1001} className="leads-sidebar" >
            <div className="sideheader">
              <div className="view-details-div">
                <label style={{color:'#ED7D31',fontFamily:"Calibri",fontWeight:"600",fontSize:"13px"}} >Property Details</label>
                <div className="viewdetailsmap">
                <iframe className="viewdetailsmapstyle"
  loading="lazy"
  allowFullScreen
  referrerPolicy="no-referrer-when-downgrade"
  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyB5Bb90wAs9U0cLmj-8hSlQVf_lQd_jJH8
  &q=${mapURL}`}>
</iframe>
                </div>
                <div className="view-details-style" >
                <p>Property Name: <span>{leadsInfo.prop_name !== null ? leadsInfo.prop_name : 'Not Available'}</span></p>
                <p>Address: <span style={{ float: "right", fontWeight:"600" }}>{leadsInfo.prop_address !== null ? leadsInfo.prop_address : 'Not Available'}</span></p>
                  <p >City: <span style={{ float: "right", fontWeight: "600" }}>{leadsInfo.prop_city !== null ? leadsInfo.prop_city : 'Not Available'}</span></p>
                  <p >State: <span style={{ float: "right", fontWeight: "600" }}>{leadsInfo.prop_state !== null ? leadsInfo.prop_state : 'Not Available'}</span></p>
                  <p >Zip: <span style={{ float: "right", fontWeight: "600" }}>{leadsInfo.prop_zip !== null ? leadsInfo.prop_zip : 'Not Available'}</span></p>
                  <p >Property Units: <span style={{ float: "right", fontWeight: "600" }}>{leadsInfo.units !== null ? leadsInfo.units : 'Not Available'}</span></p>
                  <p style={{ textAlign: "left" }}>Property Area: <span style={{ float: "right", fontWeight: "600" }}>
                    <NumberFormat
                      value={leadsInfo.prop_sqrt}
                      displayType={"text"}
                      thousandSeparator={true}
                    />&nbsp;&nbsp;Sq.Ft.</span>
                  </p>
                  <p >Year Built: <span style={{ float: "right", fontWeight: "600" }}>{leadsInfo.prop_built !== null ? leadsInfo.prop_built : 'Not Available'}</span></p>
                  <p >Last Sale: <span style={{ float: "right", fontWeight: "600" }}>{leadsInfo.last_sale !== null ? moment(leadsInfo.last_sale).format("MM/DD/YYYY") : 'Not Available'}</span></p>
                 {status === "LEAD" ?
                  <p >Propensity: <span style={{ float: "right", fontWeight: "600" }}>{leadsInfo.propensity !== null ? leadsInfo.propensity : 'Not Available'}</span></p>
                  :null}
                  <p>Last Sale Amount: <span style={{ float: "right", fontWeight:"600" }}>{leadsInfo.last_sale_amount !== null ? `$${(leadsInfo.last_sale_amount/1000000).toFixed(2)}M` : 'Not Available'}</span></p>
                   <p >Pricing Expectation: 
                    <span style={{ float: "right", fontWeight: "600",width:"48%"}}>
                   {extraOwnerContact !== undefined && extraOwnerContact?.list_price !== null && editPrice === false? <>
                    <label>{extraOwnerContact.list_price}</label>
                    <i onClick={() =>setEditPrice(true)} style={{fontSize:"0.7rem",cursor:"pointer",marginLeft:"5px"}} className="pi pi-user-edit"></i> 
                    
                   </>
                   :editPrice === true ?
                   <div className="editnotesinput">
                   <InputText keyfilter="int" required  placeholder="Enter Price" 
                   value={sPropertyPrice} onChange={(e) => setSPropertyPrice(e.target.value)}
                   />
                 <i style={{fontSize:"0.7rem",cursor:"pointer",marginLeft:"5px"}} onClick={() => updateContactDetails(id)} className="pi pi-check"></i> 
                 </div>
                    : 
                    <i onClick={() =>setEditPrice(true)} style={{fontSize:"0.7rem",cursor:"pointer",marginLeft:"5px"}} className="pi pi-user-edit"></i> 
                    }</span></p>
                  <Divider />
                </div>
                <Accordion expandIcon="pi pi-chevron-down" collapseIcon="pi pi-chevron-up">
                <AccordionTab header="Owner Details">
                <div className="view-details-style" >
                <p>Owner Name: <span style={{ float: "right", fontWeight:"600" }}>{leadsInfo.owner_name !== null ? leadsInfo.owner_name : 'Not Available'}</span></p>
                  <p >Contact Name: <span style={{ float: "right", fontWeight: "600" }}>{leadsInfo.contact_name !== null ? leadsInfo.contact_name : 'Not Available'}</span></p>
                  <p >Address: <span style={{ float: "right", fontWeight: "600" }}>{leadsInfo.owner_address !== null ? leadsInfo.owner_address : 'Not Available'}</span></p>
                  <p >City: <span style={{ float: "right", fontWeight: "600" }}>{leadsInfo.owner_city !== null ? leadsInfo.owner_city : 'Not Available'}</span></p>
                  <p >State: <span style={{ float: "right", fontWeight: "600" }}>{leadsInfo.owner_state !== null ? leadsInfo.owner_state : 'Not Available'}</span></p>
                  <p >Contact: <span style={{ float: "right", fontWeight: "600" }}>{leadsInfo.owner_contact_number !== null ? formatPhoneNumber(leadsInfo.owner_contact_number) : 'Not Available'}</span></p>
                  <p >Secondary Contact Name:  
                  <span style={{ float: "right", fontWeight: "600",width:"48%" }}>
                    {extraOwnerContact !== undefined && extraOwnerContact.contact_name !== null && edit === false  ? 
                    <>
                    <label>{extraOwnerContact.contact_name}</label>
                    <i onClick={() =>setEdit(true)}style={{fontSize:"0.7rem",cursor:"pointer",marginLeft:"5px"}} className="pi pi-user-edit"></i> 
                    </>
                    : edit === true ? 
                    <div className="editnotesinput">
                      <InputText required placeholder="Enter Contact Name"
                       value={scontactname} onChange={(e) => setSContactName(e.target.value)} 
                       />
                      <i style={{fontSize:"0.7rem",cursor:"pointer",marginLeft:"5px"}} onClick={() => updateContactDetails(id)}  className="pi pi-check"></i> 
                    </div>
                    :<i onClick={() =>setEdit(true)}style={{fontSize:"0.7rem",cursor:"pointer",marginLeft:"5px"}} className="pi pi-user-edit"></i> 
                    }
                    </span></p>
                  <p >Secondary Contact Number: 
                    <span style={{ float: "right", fontWeight: "600",width:"48%"}}>
                   {extraOwnerContact !== undefined && extraOwnerContact.contact_number !== null && edit === false? <>
                    <label>{formatPhoneNumber(extraOwnerContact.contact_number)}</label>
                    <a href={`tel:${formatPhoneNumber(extraOwnerContact.contact_number)}`}><i onClick={() =>addPhoneAction(extraOwnerContact)} style={{fontSize:"0.7rem",cursor:"pointer",marginLeft:"5px"}} className="bi bi-telephone"></i></a>
                    <i onClick={() =>setEdit(true)}style={{fontSize:"0.7rem",cursor:"pointer",marginLeft:"5px"}} className="pi pi-user-edit"></i>                 
                   </>
                   :edit === true ?
                   <div className="editnotesinput">
                   <InputText keyfilter="int" required  placeholder="Enter Contact Number" 
                   value={scontactnumber} onChange={(e) => setSContactNumber(e.target.value)}
                   />
                 <i style={{fontSize:"0.7rem",cursor:"pointer",marginLeft:"5px"}} onClick={() => updateContactDetails(id)}  className="pi pi-check"></i> 
                 </div>
                    : 
                    <i onClick={() =>setEdit(true)}style={{fontSize:"0.7rem",cursor:"pointer",marginLeft:"5px"}} className="pi pi-user-edit"></i> 
                    }</span></p>
                      {secondarycontacterror ?
                <>
                <label className="contacterror">Invalid Contact</label>
                </>
                : null}
                  {secondarycontactdetailserror ?
                <>
                <label className="contacterror">Both fields are mandatory</label>
                </>
                : null}
                 <p >Secondary Owner Email : 
                    <span style={{ float: "right", fontWeight: "600",width:"48%"}}>
                   {extraOwnerContact !== undefined && extraOwnerContact?.contact_email !== null && editOwnerEmail === false? <>
                    <label>{extraOwnerContact.contact_email}</label>
                    <a href={`mailto:${extraOwnerContact.contact_email}`}><i onClick={() =>addMailAction(extraOwnerContact)} style={{fontSize:"0.7rem",cursor:"pointer",marginLeft:"5px"}} className="pi pi-send"></i></a>
                    <i onClick={() =>setEditOwnerEmail(true)}style={{fontSize:"0.7rem",cursor:"pointer",marginLeft:"5px"}} className="pi pi-user-edit"></i>        
                   </>
                   :editOwnerEmail === true ?
                   <div className="editnotesinput">
                   <InputText  required  placeholder="Enter Owner Email" 
                   value={scontactemail} onChange={(e) => setSContactEmail(e.target.value)}
                   />
                 <i style={{fontSize:"0.7rem",cursor:"pointer",marginLeft:"5px"}} onClick={() => updateContactDetails(id)} className="pi pi-check"></i> 
                 </div>
                    : extraOwnerContact?.contact_name !== undefined ?
                    <i onClick={() =>setEditOwnerEmail(true)} style={{fontSize:"0.7rem",cursor:"pointer",marginLeft:"5px"}} className="pi pi-user-edit"></i> 
                    :
                    <i onClick={editAll} style={{fontSize:"0.7rem",cursor:"pointer",marginLeft:"5px"}} className="pi pi-user-edit"></i> 
                    }</span></p>
                     {secondarycontactemailerror ?
                <>
                <label className="contacterror">Invalid Email</label>
                </>
                : null}
                 {secondarycontactfielderror ?
                <>
                <label className="contacterror">All fields are mandatory</label>
                </>
                : null}
                     <Divider />
                </div>
                </AccordionTab>
                <AccordionTab header="Loan Details">
                <div className="view-details-style" >
                <p>Loan Amount: <span style={{ float: "right", fontWeight:"600" }}>{leadsInfo.loan_amount !== null ? `$${(leadsInfo.loan_amount/1000000).toFixed(2)}M` : 'Not Available'}</span></p>
                  <p >Maturity Date: <span style={{ float: "right", fontWeight: "600" }}>{leadsInfo.maturity_date !== null ? moment(leadsInfo.maturity_date).format("MM/DD/YYYY") : 'Not Available'}</span></p>
                  <p >Origination Date: <span style={{ float: "right", fontWeight: "600" }}>{leadsInfo.origination_date !== null ? moment(leadsInfo.origination_date).format("MM/DD/YYYY") : 'Not Available'}</span></p>
                  <p >Years to Mature: <span style={{ float: "right", fontWeight: "600" }}>{leadsInfo.time_to_mature !== null ? leadsInfo.time_to_mature : 'Not Available'}</span></p>
                  <Divider />
                </div>
                </AccordionTab>
                <AccordionTab header="Notes">
                <div className="view-details-style">
                  <div className="addnotesdiv">
                <div className="addnotesinput">
                <InputText required value={addLeadNotes} onChange={(e) => setAddLeadNotes(e.target.value)} /><br />
                 </div>       
               <div className="addnotesicon">
                <button onClick={() => acceptLeadFunc()} className="viewdetailsaddnotesbtn">Add</button>
                {/* <i onClick={() => toggleLeadsNotes(id)} className="pi pi-eye notesicon"></i> */}
               </div>
                  </div>
               </div>
                  <div className="viewnotesdiv">
                  <DataTable className="rbbuyerstb"
                value={LeadNotes}
                responsiveLayout="scroll"
                resizableColumns
              >
                <Column header="Notes" body={leadnotesviewdetails}/>
                {/* <Column header="Added On"  body={leadnotesdate}/> */}
              </DataTable>
                  </div>
                </AccordionTab>
                </Accordion>
              </div>
            </div>
          </Sidebar>
      </div>
      {addNotesDialogue()}
      {viewNotesDialogue()}
      {addLeadNotesDialogue()}
      {viewAllLeadsNotesDialogue()}
    </>
  );
};

export default LeadsCards;